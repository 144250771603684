import { ChangeDetectionStrategy, Component, ElementRef, Input, output, ViewChild } from '@angular/core';

@Component({
  selector: 'cc-select-preview',
  standalone: true,
  imports: [],
  templateUrl: './select-preview.component.html',
  styleUrl: './select-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPreviewComponent {
  @Input()
  dropdownOpen = false;

  @Input()
  disabled = false;

  @Input()
  size: 'default' | 'large' = 'default';

  @Input()
  appearance: 'default' | 'ghost' = 'default';

  @Input()
  hasValue = true;

  @Input()
  placeholder = '';

  @Input()
  hasError = false;

  @ViewChild('trigger', { read: ElementRef })
  trigger: ElementRef<HTMLDivElement>;

  toggle = output<void>();
}
